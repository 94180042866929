<template>
  <div class="container container-top">
    <div class="pc">
      <div class="h5 main padding-top-44">공지사항</div>
      <div class="lp-divider-sub5 padding-top-24"></div>
      <div class="columns is-multiline">
        <!-- 공지사항 목록 -->
        <div class="column is-12">
          <div v-for="notice in notices"
               :key="notice.id"
               class="body2 flex-between board-list unselect"
               @click="clickCard(notice)">
            <div class="size-16 sub notice-title">{{ notice.title }}</div>
            <div class="size-16 sub5">{{ datesFormat(notice.created_time, 'date_3') }}</div>
          </div>
          <!-- 페이지네이션 -->
          <lp-paginate :filtered="noticeFiltered"
                       @getData="getData"
                       class="margin-top-40"></lp-paginate>
        </div>
      </div>
    </div>
    <div class="mobile">
      <div class="h7 main padding-top-24">공지사항</div>
      <div class="lp-divider-sub5 padding-top-24"></div>
      <div class="board-list" v-for="notice in notices" :key="notice.id"
           @click="clickCard(notice)">
        <div class="subtitle6 main notice-title">{{ notice.title }}</div>
        <!--<div class="body5 sub3 ellipsis-2" v-html="notice.content"></div>-->
        <div class="body6 sub3 margin-top-8">{{ datesFormat(notice.created_time, 'date_3') }}</div>
      </div>
      <!-- 페이지네이션 -->
      <lp-paginate :filtered="noticeFiltered"
                   @getData="getData"
                   class="margin-top-40"></lp-paginate>
    </div>
  </div>
</template>
<script>
  import LpPaginate from "../component/LpPaginate";
  export default {
    name: "BoardNotice",
    components: {LpPaginate},
    created() {
      this.getData();
    },
    data() {
      return {
        notices: [],
        noticeFiltered: {
          cur_page: 1,
          page_num:1,
          total_page:1,
          page_length: 9
        }
      }
    },
    computed: {},
    methods: {
      clickCard(notice) {
        this.routeGa('', '공지사항', notice.title);
        this.routerPush(`notice/detail?id=${notice.id}`, true);
      },
      getData() {
        this.$axios.get('public/board/notice', {
          params: this.noticeFiltered
        }).then(res=>{
          this.notices = res.data.data;
          this.noticeFiltered.page_length = res.data.page_length;
          this.noticeFiltered.cur_page = res.data.cur_page;
          this.noticeFiltered.total_page = res.data.total_page;
        })
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .pc .board-list
    border-bottom 1px solid $gray2
    padding 16px 0

  .mobile .board-list
    padding 12px 0
    border-bottom 1px solid $gray3

  .notice-title
    text-overflow ellipsis
    overflow hidden
    white-space nowrap

</style>
